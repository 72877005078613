import React, { useEffect, useState } from 'react';
import { usePopperTooltip } from 'react-popper-tooltip';

import { CheckedIcon } from './icons/CheckedIcon';
import { LockIcon } from './icons/LockIcon';

export interface Step {
  id: string;
  label: string;
  progress: number;
  completed?: boolean;
  disabled?: boolean;
}

export interface StepperProps {
  steps: Step[];
  currentStep: string;
  onStepChange: (stepId: string) => void;
  maxVisibleSteps?: number;
}

export const Stepper: React.FC<StepperProps> = ({
  steps,
  currentStep,
  onStepChange,
  maxVisibleSteps = 6,
}) => {
  const currentStepIndex = steps.findIndex((s) => s.id === currentStep);
  const initialStart =
    currentStepIndex < maxVisibleSteps
      ? 0
      : Math.min(currentStepIndex, steps.length - maxVisibleSteps);
  const [visibleStart, setVisibleStart] = useState(initialStart);

  const [showTitles, setShowTitles] = useState(window.innerWidth >= 1000);

  useEffect(() => {
    const handleResize = () => {
      setShowTitles(window.innerWidth >= 1000);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    setVisibleStart((prevStart) => {
      if (prevStart > steps.length - maxVisibleSteps) {
        return Math.max(0, steps.length - maxVisibleSteps);
      }
      return prevStart;
    });
  }, [steps, maxVisibleSteps]);

  useEffect(() => {
    const newIndex = steps.findIndex((s) => s.id === currentStep);
    if (newIndex < visibleStart) {
      setVisibleStart(newIndex);
    } else if (newIndex >= visibleStart + maxVisibleSteps) {
      const newStart = Math.min(newIndex, steps.length - maxVisibleSteps);
      setVisibleStart(newStart);
    }
  }, [currentStep, steps, maxVisibleSteps, visibleStart]);

  // Keyboard navigation for left/right arrow keys.
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'ArrowLeft') {
        if (visibleStart > 0) {
          setVisibleStart((prev) => prev - 1);
          e.preventDefault();
        }
      } else if (e.key === 'ArrowRight') {
        if (visibleStart + maxVisibleSteps < steps.length) {
          setVisibleStart((prev) => prev + 1);
          e.preventDefault();
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [visibleStart, maxVisibleSteps, steps.length]);

  const visibleSteps = steps.slice(
    visibleStart,
    visibleStart + maxVisibleSteps
  );

  const LeftArrowIcon = () => (
    <svg
      className='w-4 h-4'
      fill='none'
      stroke='currentColor'
      viewBox='0 0 24 24'
    >
      <path
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={2}
        d='M15 19l-7-7 7-7'
      />
    </svg>
  );
  const RightArrowIcon = () => (
    <svg
      className='w-4 h-4'
      fill='none'
      stroke='currentColor'
      viewBox='0 0 24 24'
    >
      <path
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={2}
        d='M9 5l7 7-7 7'
      />
    </svg>
  );

  const renderNode = (
    step: Step,
    globalIndex: number,
    isActive: boolean,
    isLocked: boolean,
    showAsCompleted: boolean,
    narrow: boolean
  ) => {
    const node = (
      <div
        className={`cursor-pointer rounded-full border-2 flex items-center justify-center w-8 h-8 
          ${
            isActive
              ? 'bg-lp-green-001 text-white border-none'
              : showAsCompleted
              ? 'bg-lp-green-001 border-black text-white'
              : isLocked
              ? 'bg-gray-400 border-gray-400 text-gray-600'
              : 'bg-gray-200 border-gray-300 text-gray-600'
          } ${isLocked ? 'cursor-not-allowed' : ''}`}
        onClick={() => {
          if (!isLocked) onStepChange(step.id);
        }}
      >
        {showAsCompleted ? (
          <CheckedIcon className='w-4 h-4 text-white fill-current' />
        ) : isLocked ? (
          <LockIcon className='w-4 h-4 text-white fill-current' />
        ) : (
          globalIndex + 1
        )}
      </div>
    );

    // Only wrap in tooltip for active steps when not in narrow mode.
    if (!narrow && isActive && !showAsCompleted && !isLocked) {
      return <Tooltip content={`${step.progress}% complete`}>{node}</Tooltip>;
    }
    return node;
  };

  return (
    <div className='w-full flex items-center'>
      {/* Left arrow container with reserved width */}
      <div className='w-10 flex items-center justify-center'>
        {visibleStart > 0 ? (
          <button
            type='button'
            onClick={() => setVisibleStart(visibleStart - 1)}
            className='p-2 text-gray-600 hover:text-gray-800'
          >
            <LeftArrowIcon />
          </button>
        ) : (
          <div className='w-10' />
        )}
      </div>

      {/* Steps container */}
      <div className='flex-1 relative'>
        <div className='flex items-center'>
          {visibleSteps.map((step, i) => {
            const globalIndex = visibleStart + i;
            const isActive = step.id === currentStep;
            const isLocked = !!step.disabled;
            const showAsCompleted = !isLocked && !!step.completed;
            const currentStepProgress = step.progress || 0;
            let connectorFill = 0;
            if (showAsCompleted) {
              connectorFill = 100;
            } else if (!isLocked) {
              connectorFill = currentStepProgress;
            }

            const stepButton = (
              <div className='w-8 mx-2'>
                {showTitles
                  ? renderNode(
                      step,
                      globalIndex,
                      isActive,
                      isLocked,
                      showAsCompleted,
                      false
                    )
                  : renderNode(
                      step,
                      globalIndex,
                      isActive,
                      isLocked,
                      showAsCompleted,
                      true
                    )}
              </div>
            );

            return (
              <React.Fragment key={step.id}>
                <div className='relative'>
                  {showTitles ? (
                    <div
                      className='w-30 absolute -top-6 left-1/2 -translate-x-1/2 cursor-pointer'
                      onClick={(e) => {
                        e.stopPropagation();
                        if (!isLocked) onStepChange(step.id);
                      }}
                    >
                      <p
                        className={`text-xs ${
                          isActive
                            ? 'text-white font-bold'
                            : isLocked
                            ? 'text-gray-500'
                            : 'text-gray-400'
                        } text-center truncate`}
                        title={step.label}
                      >
                        {step.label}
                      </p>
                    </div>
                  ) : null}

                  {/* In narrow mode, wrap the node in a tooltip to show the title */}
                  {showTitles ? (
                    stepButton
                  ) : (
                    <Tooltip content={step.label}>{stepButton}</Tooltip>
                  )}
                </div>
                {i < visibleSteps.length - 1 && (
                  <div className='flex-1 h-1 bg-gray-300 relative'>
                    <div
                      className='h-full bg-lp-green-001 transition-all duration-300'
                      style={{ width: `${connectorFill}%` }}
                    />
                  </div>
                )}
              </React.Fragment>
            );
          })}
        </div>
      </div>

      {/* Right arrow container */}
      <div className='w-10 flex items-center justify-center'>
        {visibleStart + maxVisibleSteps < steps.length ? (
          <button
            type='button'
            onClick={() => setVisibleStart(visibleStart + 1)}
            className='p-2 text-gray-600 hover:text-gray-800 flex items-center'
          >
            <RightArrowIcon />
            <span className='ml-1 text-xs font-bold text-gray-600'>
              {steps.length - (visibleStart + maxVisibleSteps)}
            </span>
          </button>
        ) : (
          <div className='w-10' />
        )}
      </div>
    </div>
  );
};

export interface TooltipProps {
  content: React.ReactNode;
  placement?: 'top' | 'bottom' | 'left' | 'right';
  offset?: [number, number];
  children: React.ReactNode;
}

const Tooltip: React.FC<TooltipProps> = ({
  content,
  placement = 'top',
  offset = [0, 8],
  children,
}) => {
  const { getTooltipProps, setTooltipRef, setTriggerRef, visible } =
    usePopperTooltip({
      placement,
      offset,
      trigger: 'hover',
      interactive: true,
    });

  return (
    <>
      <span ref={setTriggerRef}>{children}</span>
      {visible && (
        <div
          ref={setTooltipRef}
          {...getTooltipProps({
            className: 'bg-black text-white text-xs p-1 rounded z-50 shadow-md',
          })}
        >
          {content}
        </div>
      )}
    </>
  );
};
