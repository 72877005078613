import {
  type ClientLoaderFunctionArgs,
  useLoaderData,
  useNavigate,
} from '@remix-run/react';
import { useEffect, useState } from 'react';
import { useToggle } from 'react-use';
import { $path } from 'remix-routes';

import {
  type DtoAssignedCourse,
  type DtoAssignedStack,
  type DtoLearnerProfileResponse,
  type DtoSingleGamePackResponse,
} from '@lp-lib/api-service-client/public';

import { useLearningAnalytics } from '../analytics/learning';
import { useUserAnalytics } from '../analytics/user';
import { UserAccess } from '../components/Access/UserAccess';
import { GlobalAudioDetectionProvider } from '../components/GameV2/apis/AudioDetection';
import { Overworld } from '../components/GameV2/Overworld';
import { ArrowDownIcon, ArrowUpIcon } from '../components/icons/Arrows/Default';
import { StackedSquaresIcon } from '../components/icons/StackedSquaresIcon';
import { getRandomAnimatedAvatar } from '../components/Participant/avatars';
import { ProvidersList } from '../components/ProvidersList';
import { Stepper } from '../components/Stepper';
import { UserContextProvider } from '../components/UserContext';
import { useLiveCallback } from '../hooks/useLiveCallback';
import { apiService } from '../services/api-service';
import { fromDTOGamePack, fromDTOGames } from '../utils/api-dto';
import { tokenWithRedirect } from '../utils/router';

export async function clientLoader(action: ClientLoaderFunctionArgs) {
  const [enrollmentData, profile] = await tokenWithRedirect(
    () =>
      Promise.all([
        apiService.learning.getUserCourses(),
        apiService.learning.getMyLearnerProfile({
          enrollmentsSummary: true,
          membershipsSummary: false,
        }),
      ]),
    action.request.url
  );

  return {
    enrollmentData: enrollmentData.data,
    profile: profile.data,
  };
}

function Loading() {
  return <div className='text-white'>Loading your courses...</div>;
}

export interface AssignedItem {
  id: string;
  name: string;
  progress: number; // percentage
  active: boolean;
  type: 'course' | 'stack';
  courseIds?: string[];
  isCompleted?: boolean;
  assignedAt: string;
}

interface AssignedItemsListProps {
  items: AssignedItem[];
  onItemClick?: (item: AssignedItem) => void;
}

function AssignedItemsList({ items, onItemClick }: AssignedItemsListProps) {
  const [completedSectionOpen, toggleCompletedSection] = useToggle(false);

  const activeCourses = items.filter((item) => !item.isCompleted);
  const completedCourses = items.filter((item) => item.isCompleted);

  return (
    <div className='w-full h-full min-h-0 flex flex-col gap-4'>
      <h2 className='flex-none text-lg font-bold'>My Courses</h2>
      <div className='flex-1 min-h-0 overflow-auto scrollbar'>
        <div className='space-y-4'>
          {/* Active Courses Section */}
          <div className='space-y-2'>
            {activeCourses.length === 0 && (
              <p className='text-icon-gray text-sm text-center'>
                Your enrolled courses
                <br />
                will appear here.
              </p>
            )}
            {activeCourses.map((item) => (
              <div
                key={item.id}
                className='relative cursor-pointer hover:bg-lp-black-003 transition-colors p-2'
                onClick={() => onItemClick?.(item)}
              >
                {/* Active indicator line */}
                {item.active && (
                  <div className='absolute left-0 top-0 h-full w-1 bg-red-500 rounded-r' />
                )}

                <div
                  className={`pl-3 ${
                    item.active ? 'text-white font-semibold' : 'text-icon-gray'
                  }`}
                >
                  <div className='flex flex-col items-start justify-between'>
                    <span className='break-words text-white'>
                      {item.name}
                      {item.type === 'stack' && (
                        <StackedSquaresIcon className='inline-block w-4 h-4 ml-2 fill-current' />
                      )}
                    </span>
                    <span className='text-xs text-gray-400'>
                      {item.progress}% complete
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* Completed Courses Section */}
          {completedCourses.length > 0 && (
            <div className='pt-2'>
              <div
                className='flex items-center cursor-pointer mb-2 text-icon-gray'
                onClick={() => toggleCompletedSection()}
              >
                <span className='text-sm font-semibold mr-2'>Completed</span>
                {completedSectionOpen ? (
                  <ArrowUpIcon className='w-3 h-3 fill-current' />
                ) : (
                  <ArrowDownIcon className='w-3 h-3 fill-current' />
                )}
              </div>

              {completedSectionOpen && (
                <div className='space-y-2 mt-2'>
                  {completedCourses.map((item) => (
                    <div
                      key={item.id}
                      className='relative cursor-pointer hover:bg-lp-black-003 transition-colors p-2'
                      onClick={() => onItemClick?.(item)}
                    >
                      {item.active && (
                        <div className='absolute left-0 top-0 h-full w-1 bg-red-500 rounded-r' />
                      )}

                      <div
                        className={`pl-3 ${
                          item.active
                            ? 'text-white font-semibold'
                            : 'text-icon-gray'
                        }`}
                      >
                        <div className='flex flex-col items-start justify-between'>
                          <span className='break-words text-white'>
                            {item.name}
                            {item.type === 'stack' && (
                              <StackedSquaresIcon className='inline-block w-4 h-4 ml-2 fill-current' />
                            )}
                          </span>
                          <span className='text-xs text-green-001'>
                            100% complete
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

function EmptyState() {
  return (
    <div className='flex items-center justify-center h-full'>
      <p className='text-white text-xl'>You have no courses assigned</p>
    </div>
  );
}

function Internal(props: {
  courses: DtoAssignedCourse[];
  stacks: DtoAssignedStack[];
  courseData: Record<string, DtoSingleGamePackResponse>;
  profile: DtoLearnerProfileResponse;
}) {
  const { courses, profile, stacks, courseData } = props;

  const [assignedItems, setAssignedItems] = useState<AssignedItem[]>([]);
  const [activeItem, setActiveItem] = useState<AssignedItem | null>(null);
  const [activeStackStep, setActiveStackStep] = useState<string | null>(null);

  const navigate = useNavigate();
  const analytics = useLearningAnalytics();

  const handlePlayGame = useLiveCallback((courseId: string) => {
    navigate($path('/game-packs/:id/overworld', { id: courseId }));
  });

  const handlePlayStack = useLiveCallback((courseId: string) => {
    navigate($path('/stacks/:id/overworld', { id: courseId }));
  });

  const getCourseProgress = (course: DtoSingleGamePackResponse) => {
    const { progression: prog } = course;
    return prog?.completedAt
      ? 100
      : Math.floor((prog?.blockProgressPct ?? 0) * 100);
  };

  useEffect(() => {
    if (
      activeItem?.type === 'stack' &&
      activeItem.courseIds &&
      activeItem.courseIds.length
    ) {
      const courseIds = activeItem.courseIds;

      const latestCourseId = courseIds.reduce((latest, courseId, index) => {
        const course = courseData[courseId];
        const progress = getCourseProgress(course);

        // If this is the first course, it's never locked
        if (index === 0) {
          return courseId;
        }

        // Check if this course is locked by looking at previous course completion
        const prevCourse = courseData[courseIds[index - 1]];
        const prevProgress = getCourseProgress(prevCourse);
        const isLocked = prevProgress < 100;

        // If the previous course is complete (100%) and this course isn't locked,
        // this becomes our new latest course regardless of its progress
        if (!isLocked && prevProgress === 100) {
          return courseId;
        }

        // If this course isn't locked and has some progress
        if (!isLocked && progress > 0) {
          return courseId;
        }

        return latest;
      }, null as string | null);
      setActiveStackStep(latestCourseId || courseIds[0]);
    }
  }, [activeItem, courseData]);

  useEffect(() => {
    // Transform courses and stacks into assigned items for the sidebar
    const assignedCourses = courses.map((assignment) => {
      const course = courseData[assignment.id];
      const { gamePack: gp, progression: prog } = course;
      const progress = getCourseProgress(course);
      const isCompleted = Boolean(prog?.completedAt);

      return {
        id: gp.id,
        name: gp.name,
        progress: progress,
        isCompleted,
        active: false,
        type: 'course' as const,
        assignedAt: assignment.assignedAt,
      };
    });

    const assignedStacks = stacks.map((assignment) => {
      const courseProgresses = assignment.courseIds.map((courseId) => {
        const course = courseData[courseId];
        return getCourseProgress(course);
      });

      const averageProgress = Math.round(
        courseProgresses.reduce((acc, curr) => acc + curr, 0) /
          courseProgresses.length
      );

      const isCompleted = Boolean(assignment.completedAt);

      return {
        id: assignment.id,
        name: assignment.name,
        progress: averageProgress,
        isCompleted,
        active: false,
        type: 'stack' as const,
        courseIds: assignment.courseIds,
        assignedAt: assignment.assignedAt,
      };
    });

    // Combine and sort by assigned date descending
    const items = [...assignedCourses, ...assignedStacks].sort((a, b) =>
      b.assignedAt.localeCompare(a.assignedAt)
    );

    if (items.length > 0) {
      // Select the first non-completed item as active by default
      const firstActive = items.find((item) => !item.isCompleted) || items[0];
      items.forEach((item) => {
        item.active = item.id === firstActive.id;
      });
      setActiveItem(firstActive);
    }

    setAssignedItems(items);
  }, [courses, courseData, stacks]);

  if (!courses) {
    return <Loading />;
  }

  let overworldContent = null;

  if (activeItem) {
    if (activeItem.type === 'course') {
      const activeCourse = courseData[activeItem.id];
      const { gamePack: gp, games: gs, progression: prog } = activeCourse;
      const pack = fromDTOGamePack(gp);
      const gms = fromDTOGames(gs || []);

      overworldContent = (
        <Overworld
          pack={pack}
          games={gms}
          progression={prog}
          onClickGame={() => {
            analytics.trackCourseStarted({
              packId: pack.id,
              packName: pack.name,
              groupId: gp.id,
              groupName: gp.name,
              isCourseCreator: gp.uid === profile.uid,
            });
            handlePlayGame(gp.id);
          }}
          displayOptions={{ showLpLogo: false }}
        />
      );
    } else if (activeItem.type === 'stack') {
      // Compute step details for each course in the stack
      const steps =
        activeItem.courseIds?.map((courseId, index) => {
          const course = courseData[courseId];
          const { gamePack: gp } = course;
          const progress = getCourseProgress(course);
          const completed = progress === 100;
          // For any step beyond the first, disable it unless the previous course is complete.
          let disabled = false;
          if (index > 0) {
            const prevCourse =
              courseData[activeItem.courseIds?.[index - 1] ?? 0];
            disabled = getCourseProgress(prevCourse) < 100;
          }
          return {
            id: courseId,
            label: gp.name,
            disabled,
            completed,
            progress,
          };
        }) || [];

      overworldContent = (
        <div className='w-full h-full p-4'>
          <Stepper
            steps={steps}
            currentStep={
              activeStackStep ||
              (activeItem.courseIds ? activeItem.courseIds[0] : '')
            }
            onStepChange={(stepId) => setActiveStackStep(stepId)}
          />
          <div className='w-full h-full'>
            {activeItem.courseIds?.map((courseId) => {
              if (courseId !== activeStackStep) return null;
              const course = courseData[courseId];
              const { gamePack: gp, games: gs, progression: prog } = course;
              const pack = fromDTOGamePack(gp);
              const gms = fromDTOGames(gs || []);
              return (
                <Overworld
                  key={courseId}
                  pack={pack}
                  games={gms}
                  progression={prog}
                  onClickGame={() => {
                    analytics.trackCourseStarted({
                      packId: pack.id,
                      packName: pack.name,
                      groupId: gp.id,
                      groupName: gp.name,
                      isCourseCreator: gp.uid === profile.uid,
                    });
                    handlePlayStack(activeItem.id);
                  }}
                  displayOptions={{ showLpLogo: false }}
                />
              );
            })}
          </div>
        </div>
      );
    }
  }

  const handleItemClick = (item: AssignedItem) => {
    const id = item.id;
    const selected = assignedItems.find((item) => item.id === id);
    if (!selected) return;
    setAssignedItems((prev) =>
      prev.map((item) => ({ ...item, active: item.id === id }))
    );
    setActiveItem(item);
    analytics.trackCourseSelected({
      packId: id,
      packName: selected.name,
    });
  };

  return (
    <div className='w-full h-full flex text-white'>
      <div className='w-75 bg-main-layer text-white flex flex-col'>
        <div className='p-2'>
          <LearnerProfileCard profile={profile} />
        </div>
        <div className='flex-1 min-h-0 p-4 pr-2'>
          <AssignedItemsList
            items={assignedItems}
            onItemClick={handleItemClick}
          />
        </div>
      </div>
      <div className='flex-1 p-8 overflow-auto'>
        {courses.length > 0 ? (
          overworldContent ? (
            overworldContent
          ) : (
            <div className='text-white'>Select a course from the sidebar</div>
          )
        ) : (
          <EmptyState />
        )}
      </div>
    </div>
  );
}

function LearnerProfileCard(props: { profile: DtoLearnerProfileResponse }) {
  return (
    <div className='w-full bg-black rounded-lg'>
      <div className='p-4 flex-1 flex items-center gap-2'>
        <div className='flex-none rounded-full w-12.5 h-12.5 bg-secondary overflow-hidden'>
          <video
            src={getRandomAnimatedAvatar(props.profile.uid)}
            className='w-full h-full object-cover'
            muted
            autoPlay
            loop
            controls={false}
          />
        </div>
        <div className='flex-1 min-w-0'>
          <div className='w-full font-bold text-white text-lg truncate'>
            {props.profile.name}
          </div>
          <div className='w-full text-icon-gray text-xs truncate'>
            {props.profile.email}
          </div>
        </div>
      </div>
      {props.profile.enrollmentsSummary && (
        <div className='w-full flex items-center justify-center gap-5 py-5'>
          <div className='w-16 flex flex-col items-center gap-1 text-white text-center'>
            <div className='font-bold text-2xl'>
              {props.profile.enrollmentsSummary.activeCount}
            </div>
            <div className='text-[11px]'>Active</div>
          </div>
          <div className='w-16 flex flex-col items-center gap-1 text-white text-center'>
            <div className='font-bold text-2xl'>
              {props.profile.enrollmentsSummary.completedCount}
            </div>
            <div className='text-[11px]'>Completed</div>
          </div>
          <div className='w-16 flex flex-col items-center gap-1 text-white text-center'>
            <div className='font-bold text-2xl'>
              {props.profile.enrollmentsSummary.totalCount}
            </div>
            <div className='text-[11px]'>All Courses</div>
          </div>
        </div>
      )}
    </div>
  );
}

export function Component() {
  const { enrollmentData, profile } = useLoaderData<typeof clientLoader>();
  const providers = [
    <UserContextProvider useUserAnalytics={useUserAnalytics} />,
    <UserAccess allowGuests />,
    <GlobalAudioDetectionProvider />,
  ];

  return (
    <ProvidersList providers={providers}>
      <Internal
        courses={enrollmentData.courses ?? []}
        stacks={enrollmentData.stacks ?? []}
        courseData={enrollmentData.courseData}
        profile={profile}
      />
    </ProvidersList>
  );
}
